import {
  EditButtonText,
  EditSubjectBtn,
  OverviewHeaderContainer,
  RatingContainer,
  SubjectCard,
  SubjectImg,
  SubjectImgPlaceHolder,
  SubjectInfoContainer,
  SubjectLastUpdated,
  SubjectTitle,
  RatingNumber,
} from "./OverviewHeader.styled";
import SubjectHeaderSkeleton from "@Components/Skeletons/SubjectHeaderSkeleton";
import Rating from "@mui/material/Rating";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import RatingEmptyStar from "images/icons/RatingEmptyStar";
import RatingStarYellow from "images/icons/RatingStarYellow";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSubjectName } from "utils/Strings";

type Props = {
  openPopup: Dispatch<SetStateAction<boolean>>;
};

const OverviewHeader = ({ openPopup }: Props) => {
  const { subjectDetails, isSubjectDetailsloading } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  const [imageHasError, setImageHasError] = useState<boolean>(false);

  return (
    <Fragment>
      {!isSubjectDetailsloading ? (
        <OverviewHeaderContainer>
          <SubjectCard>
            {!imageHasError || subjectDetails?.image?.url ? (
              <SubjectImg
                src={subjectDetails?.image?.url}
                onError={() => setImageHasError(true)}
                alt={getSubjectName(subjectDetails, language)}
              />
            ) : (
              <SubjectImgPlaceHolder />
            )}
            <SubjectInfoContainer>
              <SubjectTitle>{getSubjectName(subjectDetails, language)}</SubjectTitle>
              {subjectDetails?.created_at && (
                <SubjectLastUpdated>{`${tCommon("date_added")} ${moment(subjectDetails?.created_at).format(
                  "DD/MM/YYYY"
                )}`}</SubjectLastUpdated>
              )}
              <RatingContainer>
                <Rating
                  name="read-only"
                  value={subjectDetails?.rating || 0}
                  precision={0.5}
                  icon={<RatingStarYellow />}
                  emptyIcon={<RatingEmptyStar />}
                  readOnly
                />
                <RatingNumber>{subjectDetails?.rating || 0}</RatingNumber>
              </RatingContainer>
            </SubjectInfoContainer>
          </SubjectCard>
          <EditSubjectBtn
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                openPopup(true);
              }
            }}
          >
            <EditButtonText>{tCommon("edit_subject_details")}</EditButtonText>
          </EditSubjectBtn>
        </OverviewHeaderContainer>
      ) : (
        <SubjectHeaderSkeleton />
      )}
    </Fragment>
  );
};

export default OverviewHeader;
