import styled, { css } from "styled-components";

export const SubjectDiscountTabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  padding: 4px;
  border-radius: 8px;
`;

export const DiscountTab = styled.div<{ readonly isActive?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 6px;
  font-size: ${({ theme }) => theme.fontSizes.body14};
  font-weight: ${({ theme }) => theme.fontWeights.font300};
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.font500};
      color: ${({ theme }) => theme.colors.nero};
      background-color: ${theme.colors.white};
    `}
`;

export const EquivalentDiscount = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body14};
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  color: ${({ theme }) => theme.colors.gray3};
  padding: 0 16px;
  margin-top: -8px;
`;
