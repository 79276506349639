import {
  AddButton,
  ButtonText,
  HeaderContainer,
  HeaderTitle,
  LastUpdate,
  SubjectsHeaderTitle,
} from "./RegularHeader.styled";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";
import { EditIcon } from "images/icons/EditIcon";
import { Dispatch, SetStateAction } from "react";

type Props = {
  icon?: boolean;
  title: string;
  lastUpdate: string;
  buttonTitle: string;
  openPopup: Dispatch<SetStateAction<boolean>>;
};

const RegularHeader = ({ icon, title, lastUpdate, buttonTitle, openPopup }: Props) => {
  const { userData } = userInfo();
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  return (
    <HeaderContainer>
      <SubjectsHeaderTitle>
        <HeaderTitle>{title}</HeaderTitle>
        {lastUpdate !== null && <LastUpdate>{lastUpdate}</LastUpdate>}
      </SubjectsHeaderTitle>
      {!isRegularInstructor && (
        <AddButton onClick={() => openPopup(true)}>
          {icon && <EditIcon />}
          <ButtonText>{buttonTitle}</ButtonText>
        </AddButton>
      )}
    </HeaderContainer>
  );
};

export default RegularHeader;
