import {
  CenterDiv,
  ColorPickerContainer,
  DashboardContainer,
  DeleteFile,
  DisplayColor,
  FileInputWrapper,
  FileNameWrapper,
  PickColorWrapper,
  PopupSectionTitle,
  StyledPTag,
  SubjectImage,
  UploadFileInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import BreadcrumbsMenuPortal from "@Components/Breadcrumbs/BreadcrumbPortalMenu";
import BreadcrumbPortalButton from "@Components/Breadcrumbs/BreadcrumbPortalMenu/BreadcrumbPortalButton";
import {
  BreadcrumbText,
  CategoriesWrappers,
  CategoryError,
  DeleteCategoryIcon,
  DisplayBreadcrumb,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/PopupsLayout";
import { ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import { FormContainer, FormWrapper, Input, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _CreateSubject } from "@Services/Subjects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { imagesTypes } from "constants/Types";
import { ADMIN } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import { CloseIcon } from "images/icons/CloseIcon";
import UploadIcon from "images/icons/UploadIcon";
import { useContext, Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { ChromePicker } from "react-color";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CategoriesListType } from "types/Category";
import { Subject } from "types/Subjects";
import { getErrorMessage } from "utils/Forms";
import { compareEmojis, convertSize, isEmptyValue, removeDuplicates } from "utils/Strings";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  subjectsRefetch: () => void;
}

const AddSubjectPopup = ({ isOpen, closePopup, subjectsRefetch }: Props) => {
  const { userData } = userInfo();
  const { teacherId } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  // Form States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");

  // Image States & validation
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isdropActive, setIsdropActive] = useState<boolean>(false);
  const [imageValidation, setImageValidation] = useState<string>("");

  // Color States
  const colorPickerRef = useRef<HTMLInputElement>(null);
  const [color, setColor] = useState<string>("#45AAF2");
  const [openColorPalltie, setOpenColorPalltie] = useState<boolean>(false);

  // Categories States & Validation
  const categorySelectRef = useRef<HTMLDivElement>(null);
  const [categoriesList, setCategoriesList] = useState<CategoriesListType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [categoriesDropdown, setCategoriesDropdown] = useState<boolean>(false);
  const [categoriesValidation, setCategoriesValidation] = useState<string>("");
  const lastCategoriesIds = categoriesList?.map((category) => Number(category.id));

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Subject>();

  const updateColor = (newColor: string) => {
    setColor(newColor);
    setValue("color", newColor);
  };

  const removeCategory = (categoryId: number) => {
    setCategoriesList(categoriesList?.filter((category) => category.id !== categoryId));
    setCategoriesDropdown(false);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSubmitValidation("");
      setImageFile(null);
      setImageValidation("");
      setColor("#45AAF2");
      setOpenColorPalltie(false);
      setValue("color", "#45AAF2");
      setCategoriesList([]);
      setSelectedCountry("");
      setCategoriesValidation("");
      setCategoriesDropdown(false);
    }
  }, [isOpen]);

  // For just disabled other categories when user select one
  useEffect(() => {
    if (categoriesList?.length > 0) {
      setCategoriesValidation("");
      const firstEmojie = categoriesList[0].breadcrumbs.split("/")[0];
      if (compareEmojis(firstEmojie, "🇰🇼")) {
        setSelectedCountry("kwd");
      } else if (compareEmojis(firstEmojie, "🇸🇦")) {
        setSelectedCountry("sar");
      } else if (compareEmojis(firstEmojie, "🇯🇴")) {
        setSelectedCountry("jod");
      } else if (compareEmojis(firstEmojie, "🇧🇭")) {
        setSelectedCountry("bhd");
      }
    } else {
      setSelectedCountry("");
    }
  }, [categoriesList]);

  // For click outside to close [ categories or ColorPalltie ] dropdowns
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.className.includes("saturation-white") &&
        !event.target.className.includes("hue-horizontal") &&
        !event.target.className.includes("DisplayColor") &&
        !event.srcElement.id.includes("rc-editable-input")
      ) {
        setOpenColorPalltie(false);
      }
      if (!event.target.className.includes("Breadcrumbsstyled")) {
        setCategoriesDropdown(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const createSubjectHandler = (data: Subject) => {
    setIsLoading(true);

    if (categoriesList?.length === 0 || imageFile === null) {
      if (categoriesList?.length === 0) {
        setCategoriesValidation(tCommon("category_validation"));
        setIsLoading(false);
      }

      if (imageFile === null) {
        setImageValidation(tCommon("required_message"));
        setIsLoading(false);
      }
    } else {
      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (isEmptyValue(value)) return;
        formData.append(key, typeof value === "object" && value !== null ? JSON.stringify(value) : value);
      });

      if (lastCategoriesIds && lastCategoriesIds.length > 0) {
        removeDuplicates(lastCategoriesIds).forEach((id) => {
          formData.append("categories[]", id.toString());
        });
      }

      if (imageFile) {
        formData.append("image", imageFile);
      }

      if (userData?.roles.includes(ADMIN)) {
        formData.append("instructor_id", teacherId.toString());
      }

      _CreateSubject(formData as Subject)
        .then(() => {
          subjectsRefetch();
          closePopup(false);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
          setSubmitValidation(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(createSubjectHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_subject")}
          buttonTitle={tCommon("add_subject")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs
              isSubject={true}
              isOpen={isOpen}
              errors={errors}
              watch={watch}
              register={register}
              setValue={setValue}
              isSection={false}
              islecture={false}
            />
            <PopupSectionTitle>{tCommon("image")}</PopupSectionTitle>
            <DashboardContainer>
              <div className="container">
                {imageFile === null ? (
                  <UploadFileInput isImage isdropActive={isdropActive}>
                    <CenterDiv>
                      <UploadIcon />
                      {isdropActive ? (
                        <StyledPTag>{tCommon("drop_it_here")}</StyledPTag>
                      ) : (
                        <StyledPTag>
                          <span>{tCommon("select")}</span> {tCommon("cover_image")}
                        </StyledPTag>
                      )}
                    </CenterDiv>
                    <FileUploader
                      name="image"
                      types={imagesTypes}
                      maxSize={2}
                      onDraggingStateChange={(e) => setIsdropActive(e)}
                      handleChange={(e) => setImageFile(e)}
                      onSizeError={() => setImageValidation(tCommon("maximum_allowed"))}
                      onTypeError={() => setImageValidation(tCommon("wrong_type"))}
                    />
                  </UploadFileInput>
                ) : (
                  <UploadFileInput isImage>
                    <FileInputWrapper>
                      <FileNameWrapper>
                        <SubjectImage src={URL.createObjectURL(imageFile)} alt="subject_image" />
                        <StyledPTag textEllipsis noMargin>
                          {imageFile.name} ({convertSize(imageFile.size)})
                        </StyledPTag>
                      </FileNameWrapper>
                      <DeleteFile
                        type="button"
                        onClick={() => {
                          setImageFile(null);
                          setImageValidation("");
                        }}
                      >
                        <BoldCloseIcon />
                      </DeleteFile>
                    </FileInputWrapper>
                  </UploadFileInput>
                )}
                {imageValidation && imageFile === null && <ErrorType center>{imageValidation}</ErrorType>}
              </div>
            </DashboardContainer>
            <PopupSectionTitle>{tCommon("subject_theme_color")}</PopupSectionTitle>
            <PickColorWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("subject_color")}
                {...register("color", {
                  required: {
                    value: true,
                    message: tCommon("required_message"),
                  },
                  pattern: {
                    value: /^#([0-9A-F]{3}){1,2}$/i,
                    message: tCommon("color_validation_message"),
                  },
                })}
                value={watch("color")}
                onChange={(e) => updateColor(e.target.value)}
                error={Boolean(errors.color)}
                helperText={errors.color && errors?.color.message}
                InputLabelProps={{
                  shrink: watch("color") && true,
                }}
                InputProps={{
                  endAdornment: (
                    <DisplayColor pickedColor={color} onClick={() => setOpenColorPalltie(!openColorPalltie)} />
                  ),
                }}
                ref={colorPickerRef}
              />
              <ColorPickerContainer className="custom-chrome-picker" openColorPalltie={openColorPalltie}>
                <ChromePicker color={color} onChange={(c) => updateColor(c.hex)} />
              </ColorPickerContainer>
            </PickColorWrapper>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("light_dark_mode")}</InputLabel>
              <Controller
                name="light_mode"
                control={control}
                defaultValue={1}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value={1}>{tCommon("light_mode")}</MenuItem>
                    <MenuItem value={0}>{tCommon("dark_mode")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <PopupSectionTitle>{tCommon("study_stage_college")}</PopupSectionTitle>
            <CategoriesWrappers>
              {categoriesList.map((category, index) => (
                <DisplayBreadcrumb key={index}>
                  <BreadcrumbText>{category.breadcrumbs}</BreadcrumbText>
                  <DeleteCategoryIcon onClick={() => removeCategory(category.id)}>
                    <CloseIcon width="12" height="12" />
                  </DeleteCategoryIcon>
                </DisplayBreadcrumb>
              ))}
              <BreadcrumbPortalButton
                categorySelectRef={categorySelectRef}
                isOpen={categoriesDropdown}
                setIsOpen={setCategoriesDropdown}
                categoriesList={categoriesList}
                categoriesValidation={categoriesValidation}
              >
                {categoriesValidation && <CategoryError>{categoriesValidation}</CategoryError>}
                <BreadcrumbsMenuPortal
                  categorySelectRef={categorySelectRef}
                  categoriesDropdown={categoriesDropdown}
                  setCategoriesDropdown={setCategoriesDropdown}
                  categoriesList={categoriesList}
                  setCategoriesList={setCategoriesList}
                  selectedCountry={selectedCountry}
                />
              </BreadcrumbPortalButton>
            </CategoriesWrappers>
            <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="status"
                control={control}
                defaultValue={1}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value={1}>{tCommon("active")}</MenuItem>
                    <MenuItem value={0}>{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddSubjectPopup;
