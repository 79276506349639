import {
  CancleButton,
  DeleteButton,
  DeletePopupAlert,
  DeletePopupButtons,
  DeletePopupHeader,
  DeletePopupWrapper,
} from "../DeleteSubjectPopup/DeleteSubjectPopup.styled";
import { ErrorType, StyledRodal } from "@Components/PopupsLayout/PopupsLayout.styled";
import { _DeleteCourse } from "@Services/Courses";
import CircularProgress from "@mui/material/CircularProgress";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  courseid: string | undefined;
};

const DeleteCoursePopup = ({ isOpen, closePopup, courseid }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [displayedError, setDisplayedError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { teacherId, subjectId, refetchSubjectCourses, refetchSubjectDetails, setEditSubjectPrice } =
    useContext(TeachersDatasContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) {
      setDisplayedError("");
    }
  }, [isOpen]);

  const deleteCourseHandler = (id: string | undefined, teachId: number | null) => {
    setIsLoading(true);
    _DeleteCourse(Number(id), teachId)
      .then(() => {
        closePopup(false);
        setEditSubjectPrice(true);
        refetchSubjectCourses();
        refetchSubjectDetails();
      })
      .then(() => {
        navigate(`/${subjectId}/overview`);
      })
      .catch((err) => setDisplayedError(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledRodal isDelete visible={isOpen} onClose={() => closePopup(false)}>
      <DeletePopupWrapper>
        <DeletePopupHeader>{tCommon("delete_course_title")}</DeletePopupHeader>
        <DeletePopupAlert hasError={displayedError !== ""}>{tCommon("delete_course_message")}</DeletePopupAlert>
        {displayedError && <ErrorType>{displayedError}</ErrorType>}
        <DeletePopupButtons>
          <CancleButton variant="lightseagule" onClick={() => closePopup(false)}>
            {tCommon("cancel")}
          </CancleButton>
          <DeleteButton variant="default" disabled={isLoading} onClick={() => deleteCourseHandler(courseid, teacherId)}>
            {isLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("delete")}
          </DeleteButton>
        </DeletePopupButtons>
      </DeletePopupWrapper>
    </StyledRodal>
  );
};

export default DeleteCoursePopup;
