import {
  CheckboxWrapper,
  Dot,
  DurationTime,
  DurationType,
  EditIconWrapper,
  EditWrapper,
  FreePriceTag,
  IntroDetailsWrapper,
  IntroductionContainer,
  IntroDurationWrapper,
  IntroTitle,
  IntroWrapper,
} from "./Introduction.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { EditIcon } from "images/icons/EditIcon";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { secondsToHms } from "utils/Time";

type Props = {
  editIntroduction: Dispatch<SetStateAction<boolean>>;
  addIntroduction: Dispatch<SetStateAction<boolean>>;
};

const Introduction = ({ editIntroduction, addIntroduction }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const { courseDetails } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const [introductionSection, setIntroductionSection] = useState(null);

  useEffect(() => {
    if (courseDetails) {
      setIntroductionSection(courseDetails?.sections.filter((section) => section?.is_introductory === true));
    }
  }, [courseDetails]);

  return (
    <Fragment>
      {introductionSection && (
        <IntroductionContainer>
          <IntroWrapper>
            {/* <CheckboxWrapper>
              <input
                id="subject_checkbox"
                type="checkbox"
                checked={courseSection}
                onClick={() => setCourseSection(!courseSection)}
              />
            </CheckboxWrapper> */}
            <IntroDetailsWrapper>
              <IntroTitle>{tCommon("introduction")}</IntroTitle>
              {introductionSection[0]?.lectures[0]?.video?.id ? (
                <Fragment>
                  {introductionSection[0]?.lectures[0]?.video?.duration === 0 ? (
                    <IntroTitle>{tCommon("video_optamizing")}</IntroTitle>
                  ) : (
                    <IntroDurationWrapper>
                      <DurationType>{tCommon("video")}</DurationType>
                      <Dot />
                      <DurationTime>{secondsToHms(introductionSection[0]?.lectures[0]?.video?.duration)}</DurationTime>
                    </IntroDurationWrapper>
                  )}
                </Fragment>
              ) : (
                <IntroDurationWrapper>
                  <DurationType isEmpty={true}>{tCommon("intro_not_uploaded")}</DurationType>
                </IntroDurationWrapper>
              )}
            </IntroDetailsWrapper>
          </IntroWrapper>
          <EditWrapper>
            <FreePriceTag>{tCommon("free")}</FreePriceTag>
            <EditIconWrapper
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  if (introductionSection[0]?.lectures[0]?.video?.id) {
                    editIntroduction(true);
                  } else {
                    addIntroduction(true);
                  }
                }
              }}
            >
              <EditIcon color />
            </EditIconWrapper>
          </EditWrapper>
        </IntroductionContainer>
      )}
    </Fragment>
  );
};

export default Introduction;
