import { SubjectDetailsType } from "types/Subjects";

export const getSubjectName = (data: SubjectDetailsType, currentLanguage: string) => {
  const subjectName = data?.name[currentLanguage];
  if (subjectName !== undefined && subjectName !== "") {
    return subjectName;
  } else {
    for (const lang in data?.name) {
      if (data?.name[lang] !== "") {
        return data?.name[lang];
      }
    }
    return "Empty Name";
  }
};

export const getDefaultSubjectName = (row: any, locale: string, isSorting = false): string => {
  const langName = isSorting ? row.original.lang_name : row.row.original.lang_name;

  if (locale === "ar") {
    return getFirstNonEmptyValue(langName.ar, langName);
  } else if (locale === "en") {
    return getFirstNonEmptyValue(langName.en, langName);
  } else if (locale === "tr") {
    return getFirstNonEmptyValue(langName.tr, langName);
  } else {
    return "Empty Name";
  }
};

const getFirstNonEmptyValue = (value: string, langName: any): string => {
  if (value) {
    return value;
  } else {
    for (const lang in langName) {
      const langValue = langName[lang];
      if (langValue) {
        return langValue;
      }
    }
    return "Empty Name";
  }
};

export const downloadFile = (pdfUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = fileName;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < 1024 * 1024) {
    return Math.round(sizeInBytes / 1024) + " KB";
  } else {
    return Math.round(sizeInBytes / (1024 * 1024)) + " MB";
  }
};

export function compareEmojis(emoji1, emoji2) {
  const normalizedEmoji1 = emoji1.trim().normalize();
  const normalizedEmoji2 = emoji2.trim().normalize();
  return normalizedEmoji1 === normalizedEmoji2;
}

export function removeDuplicates(arr) {
  const seen = new Set();
  return arr.filter((item) => {
    if (seen.has(item)) {
      return false;
    } else {
      seen.add(item);
      return true;
    }
  });
}

export function isCategoriesHasChanged(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  arr1?.sort((a, b) => a - b);
  arr2?.sort((a, b) => a - b);
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export const isEmptyValue = (value: any): boolean => {
  if (value == null || value === "" || (typeof value === "string" && value.trim() === "{}")) {
    return true;
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0 || Object.values(value).every((val) => val === undefined);
  }
  return false;
};

export const convertDiscountValueToNumber = (discountvalue: string) => {
  if (discountvalue) {
    return Math.ceil(Number(discountvalue));
  }
  return 0;
};
