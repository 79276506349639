import { PopupSectionTitle } from "../AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/PopupsLayout";
import SubjectDiscount from "@Components/SubjectDiscount";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _UpdateSubjectOffer } from "@Services/Subjects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ADMIN, FINAL_PRICE_TAB, NO_DISCOUNT_TAB, PERCENTAGE_TAB } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditSubjectPrice } from "types/Subjects";
import { calculateFinalPrice, getErrorMessage } from "utils/Forms";
import { convertDiscountValueToNumber } from "utils/Strings";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSubjectPricePopup = ({ isOpen, closePopup }: Props) => {
  const { userData } = userInfo();
  const { subjectId, refetchSubjectDetails, subjectDetails, teacherId } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOfferTab, setSelectedOfferTab] = useState<string>(subjectDetails?.offer?.offer_type);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const IsAdmin = userData?.roles.includes(ADMIN);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EditSubjectPrice>();

  useEffect(() => {
    if (subjectDetails) {
      setSelectedOfferTab(subjectDetails?.offer?.offer_type);
    }
  }, [subjectDetails, isOpen]);

  useEffect(() => {
    if (subjectDetails) {
      const offerTab = subjectDetails?.offer?.offer_type;
      reset({
        currency: subjectDetails?.currency,
        discount_value:
          offerTab === NO_DISCOUNT_TAB && selectedOfferTab === NO_DISCOUNT_TAB
            ? 0
            : offerTab !== NO_DISCOUNT_TAB && selectedOfferTab === NO_DISCOUNT_TAB
            ? 0
            : offerTab === PERCENTAGE_TAB && selectedOfferTab === PERCENTAGE_TAB
            ? convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)
            : offerTab !== PERCENTAGE_TAB && selectedOfferTab === PERCENTAGE_TAB
            ? 0
            : offerTab === FINAL_PRICE_TAB && selectedOfferTab === FINAL_PRICE_TAB
            ? subjectDetails?.price - convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)
            : offerTab !== FINAL_PRICE_TAB && selectedOfferTab === FINAL_PRICE_TAB
            ? subjectDetails?.price
            : 0,
        final_price:
          offerTab === NO_DISCOUNT_TAB && selectedOfferTab === NO_DISCOUNT_TAB
            ? subjectDetails?.price
            : offerTab !== NO_DISCOUNT_TAB && selectedOfferTab === NO_DISCOUNT_TAB
            ? subjectDetails?.price
            : offerTab === PERCENTAGE_TAB && selectedOfferTab === PERCENTAGE_TAB
            ? calculateFinalPrice(
                subjectDetails?.price,
                convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)
              )
            : offerTab !== PERCENTAGE_TAB && selectedOfferTab === PERCENTAGE_TAB
            ? subjectDetails?.price
            : offerTab === FINAL_PRICE_TAB && selectedOfferTab === FINAL_PRICE_TAB
            ? convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)
            : offerTab !== FINAL_PRICE_TAB && selectedOfferTab === FINAL_PRICE_TAB
            ? subjectDetails?.price
            : 0,
      });
      setValue("offer_type", selectedOfferTab, { shouldDirty: true });
    }
    setSubmitValidation("");
  }, [subjectDetails, isOpen, selectedOfferTab]);

  useEffect(() => {
    if (selectedOfferTab === PERCENTAGE_TAB) {
      setValue("final_price", calculateFinalPrice(subjectDetails?.price, watch("discount_value")));
    }
    if (selectedOfferTab === FINAL_PRICE_TAB) {
      setValue("discount_value", watch("final_price"));
    }
  }, [watch("discount_value"), watch("final_price")]);

  const updateSubjectPriceHandler = (data: EditSubjectPrice) => {
    setIsLoading(true);
    const { currency, final_price, ...restData } = data;
    _UpdateSubjectOffer({
      item_id: subjectId,
      item_type: "subject",
      ...(IsAdmin && { instructor_id: teacherId }),
      ...restData,
    })
      .then(() => {
        closePopup(false);
        refetchSubjectDetails();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateSubjectPriceHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_subject_price")}
          buttonTitle={tCommon("save_changes")}
          buttonState={!isDirty}
          noteMessage={tCommon("edit_price_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("price")}</PopupSectionTitle>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("total_price")}
                value={subjectDetails ? subjectDetails?.price : ""}
                disabled
              />
              <FormControl variant="filled" fullWidth disabled>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("currency")}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={subjectDetails ? subjectDetails?.currency : ""}
                  render={({ field }) => (
                    <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                      <MenuItem value={subjectDetails ? subjectDetails?.currency : ""}>
                        {subjectDetails?.currency?.toLocaleUpperCase()}
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <PopupSectionTitle noBorder>{tCommon("full_purchase_discount")}</PopupSectionTitle>
            <SubjectDiscount
              register={register}
              errors={errors}
              watch={watch}
              selectedOfferTab={selectedOfferTab}
              setSelectedOfferTab={setSelectedOfferTab}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSubjectPricePopup;
