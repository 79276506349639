import {
  ActionIconsWrapper,
  CenterDiv,
  ColorPickerContainer,
  DashboardContainer,
  DeleteFile,
  DisplayColor,
  DownloadFileWrapper,
  FileInputWrapper,
  FileNameWrapper,
  PickColorWrapper,
  PopupSectionTitle,
  StyledPTag,
  SubjectImage,
  SubjectImagePlaceholder,
  UploadFileInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import BreadcrumbsMenuPortal from "@Components/Breadcrumbs/BreadcrumbPortalMenu";
import BreadcrumbPortalButton from "@Components/Breadcrumbs/BreadcrumbPortalMenu/BreadcrumbPortalButton";
import {
  BreadcrumbText,
  CategoriesWrappers,
  CategoryError,
  DeleteCategoryIcon,
  DisplayBreadcrumb,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/PopupsLayout";
import { ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import { FormContainer, FormWrapper, Input, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _UpdateSubject } from "@Services/Subjects";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { imagesTypes } from "constants/Types";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import { CloseIcon } from "images/icons/CloseIcon";
import DownloadIcon from "images/icons/DownloadIcon";
import UploadIcon from "images/icons/UploadIcon";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CategoriesListType } from "types/Category";
import { Subject } from "types/Subjects";
import { getErrorMessage } from "utils/Forms";
import {
  convertSize,
  downloadFile,
  getSubjectName,
  isCategoriesHasChanged,
  isEmptyValue,
  removeDuplicates,
} from "utils/Strings";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSubjectPopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { userData } = userInfo();
  const { subjectDetails, refetchSubjectDetails, subjectId, teacherId } = useContext(TeachersDatasContext);
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  // Form States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [multibleLanguagesChanged, setMultibleLanguagesChanged] = useState<boolean>(true);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  // Image States & validation
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isdropActive, setIsdropActive] = useState<boolean>(false);
  const [imageHasError, setImageHasError] = useState<boolean>(false);
  const [uplaodNewImage, setUplaodNewImage] = useState<boolean>(false);
  const [imageValidation, setImageValidation] = useState<string>("");

  // Color States
  const colorPickerRef = useRef<HTMLInputElement>(null);
  const [color, setColor] = useState<string>("#45AAF2");
  const [openColorPalltie, setOpenColorPalltie] = useState<boolean>(false);

  // Categories States & Validation
  const categorySelectRef = useRef<HTMLDivElement>(null);
  const [categoriesList, setCategoriesList] = useState<CategoriesListType[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [categoriesDropdown, setCategoriesDropdown] = useState<boolean>(false);
  const [categoriesValidation, setCategoriesValidation] = useState<string>("");
  const lastCategoriesIds = categoriesList?.map((category) => Number(category.id));
  const lastExistCategoriesIds = subjectDetails?.category_hierarchy?.map((item) => Number(item.id));

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Subject>();

  const updateColor = (newColor: string) => {
    setColor(newColor);
    setValue("color", newColor);
  };

  const removeCategory = (categoryId: number) => {
    if (categoriesList?.length > 1) {
      setCategoriesList(categoriesList?.filter((category) => category.id !== categoryId));
      setCategoriesDropdown(false);
    }
  };

  useEffect(() => {
    if (subjectDetails) {
      reset({
        name: {
          en: subjectDetails?.name?.en,
          ar: subjectDetails?.name?.ar,
        },
        content: {
          en: subjectDetails?.content?.en,
          ar: subjectDetails?.content?.ar,
        },
        label: {
          en: subjectDetails?.label?.en,
          ar: subjectDetails?.label?.ar,
        },
        status: subjectDetails?.status?.toString(),
        light_mode: subjectDetails?.light_mode?.toString(),
        color: subjectDetails?.color,
      });
      setSelectedCountry(subjectDetails?.currency);
      setCategoriesList(
        subjectDetails?.category_hierarchy?.map((category) => ({
          id: category?.id,
          breadcrumbs: category?.breadcrumbs,
        }))
      );
      setColor(subjectDetails?.color);
    }
    setSubmitValidation("");
    setMultibleLanguagesChanged(true);
    setImageFile(null);
    setUplaodNewImage(false);
    setImageHasError(false);
    setOpenColorPalltie(false);
    setCategoriesValidation("");
    setCategoriesDropdown(false);
  }, [subjectDetails, isOpen]);

  useEffect(() => {
    if (categoriesList?.length > 0) {
      setCategoriesValidation("");
    }
  }, [categoriesList]);

  // For click outside to close [ categories or ColorPalltie ] dropdowns
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.className.includes("saturation-white") &&
        !event.target.className.includes("hue-horizontal") &&
        !event.target.className.includes("DisplayColor") &&
        !event.srcElement.id.includes("rc-editable-input")
      ) {
        setOpenColorPalltie(false);
      }
      if (!event.target.className.includes("Breadcrumbsstyled")) {
        setCategoriesDropdown(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const updateSubjectHandler = (data: Subject) => {
    setIsLoading(true);

    const { light_mode, status, ...restData } = data;

    if (categoriesList?.length === 0 || (imageFile === null && !subjectDetails?.image?.url)) {
      if (categoriesList?.length === 0) {
        setCategoriesValidation(tCommon("category_validation"));
        setIsLoading(false);
      }

      if (imageFile === null) {
        setImageValidation(tCommon("required_message"));
        setIsLoading(false);
      }
    } else {
      const formData = new FormData();

      Object.entries(restData).forEach(([key, value]) => {
        if (isEmptyValue(value)) return;
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else if (typeof value === "object" && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value.toString());
        }
      });

      if (lastCategoriesIds && lastCategoriesIds.length > 0) {
        removeDuplicates(lastCategoriesIds).forEach((id) => {
          formData.append("categories[]", id.toString());
        });
      }

      if (imageFile) {
        formData.append("image", imageFile);
      }

      if (light_mode) {
        formData.append("light_mode", `${light_mode === "true" ? 1 : 0}`);
      }

      if (status) {
        formData.append("status", `${status === "true" ? 1 : 0}`);
      }

      if (userData?.roles.includes(ADMIN)) {
        formData.append("instructor_id", teacherId.toString());
      }

      formData.append("_method", "PUT");

      _UpdateSubject(subjectId, formData as Subject)
        .then(() => {
          refetchSubjectDetails();
          closePopup(false);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
          setSubmitValidation(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateSubjectHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_subject")}
          buttonTitle={tCommon("save_changes")}
          buttonState={
            Boolean(!isDirty && multibleLanguagesChanged) &&
            !imageFile &&
            watch("color") === subjectDetails?.color &&
            isCategoriesHasChanged(lastCategoriesIds, lastExistCategoriesIds)
          }
          closePopup={closePopup}
          noteMessage={tCommon("edit_item_message")}
          showDeleteButton={!isRegularInstructor}
          deleteTitle={tCommon("delete_subject")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs
              isSubject={true}
              isOpen={isOpen}
              data={subjectDetails}
              setValue={setValue}
              errors={errors}
              watch={watch}
              setDisablebutton={setMultibleLanguagesChanged}
              register={register}
              isSection={false}
              islecture={false}
            />
            <PopupSectionTitle>{tCommon("image")}</PopupSectionTitle>
            <DashboardContainer>
              <div className="container">
                {subjectDetails?.image?.url && !uplaodNewImage ? (
                  <UploadFileInput isImage>
                    <FileInputWrapper>
                      <FileNameWrapper>
                        {subjectDetails?.image?.url || !imageHasError ? (
                          <SubjectImage
                            src={subjectDetails?.image?.url}
                            onError={() => setImageHasError(true)}
                            alt={getSubjectName(subjectDetails, language)}
                          />
                        ) : (
                          <SubjectImagePlaceholder />
                        )}
                        <StyledPTag textEllipsis noMargin>
                          {subjectDetails?.image?.name} ({convertSize(subjectDetails?.image?.size)})
                        </StyledPTag>
                      </FileNameWrapper>
                      <ActionIconsWrapper>
                        <DownloadFileWrapper
                          onClick={() => downloadFile(subjectDetails?.image?.url, subjectDetails?.image?.name)}
                        >
                          <DownloadIcon />
                        </DownloadFileWrapper>
                        <DeleteFile type="button" onClick={() => setUplaodNewImage(true)}>
                          <BoldCloseIcon />
                        </DeleteFile>
                      </ActionIconsWrapper>
                    </FileInputWrapper>
                  </UploadFileInput>
                ) : (
                  <>
                    {imageFile === null ? (
                      <UploadFileInput isImage isdropActive={isdropActive}>
                        <CenterDiv>
                          <UploadIcon />
                          {isdropActive ? (
                            <StyledPTag>{tCommon("drop_it_here")}</StyledPTag>
                          ) : (
                            <StyledPTag>
                              <span>{tCommon("select")}</span> {tCommon("cover_image")}
                            </StyledPTag>
                          )}
                        </CenterDiv>
                        <FileUploader
                          name="image"
                          types={imagesTypes}
                          maxSize={2}
                          onDraggingStateChange={(e) => setIsdropActive(e)}
                          handleChange={(e) => setImageFile(e)}
                          onSizeError={() => setImageValidation(tCommon("maximum_allowed"))}
                          onTypeError={() => setImageValidation(tCommon("wrong_type"))}
                        />
                      </UploadFileInput>
                    ) : (
                      <UploadFileInput isImage>
                        <FileInputWrapper>
                          <FileNameWrapper>
                            <SubjectImage src={URL.createObjectURL(imageFile)} alt="subject_image" />
                            <StyledPTag textEllipsis noMargin>
                              {imageFile.name} ({convertSize(imageFile.size)})
                            </StyledPTag>
                          </FileNameWrapper>
                          <DeleteFile type="button" onClick={() => setImageFile(null)}>
                            <BoldCloseIcon />
                          </DeleteFile>
                        </FileInputWrapper>
                      </UploadFileInput>
                    )}
                  </>
                )}
                {imageValidation && imageFile === null && <ErrorType center>{imageValidation}</ErrorType>}
              </div>
            </DashboardContainer>
            <PopupSectionTitle>{tCommon("subject_theme_color")}</PopupSectionTitle>
            <PickColorWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("subject_color")}
                {...register("color", {
                  required: {
                    value: true,
                    message: tCommon("required_message"),
                  },
                  pattern: {
                    value: /^#([0-9A-F]{3}){1,2}$/i,
                    message: tCommon("color_validation_message"),
                  },
                })}
                value={watch("color")}
                onChange={(e) => updateColor(e.target.value)}
                error={Boolean(errors.color)}
                helperText={errors.color && errors?.color.message}
                InputLabelProps={{
                  shrink: watch("color") && true,
                }}
                InputProps={{
                  endAdornment: (
                    <DisplayColor pickedColor={color} onClick={() => setOpenColorPalltie(!openColorPalltie)} />
                  ),
                }}
                ref={colorPickerRef}
              />
              <ColorPickerContainer className="custom-chrome-picker" openColorPalltie={openColorPalltie}>
                <ChromePicker color={color} onChange={(c) => updateColor(c.hex)} />
              </ColorPickerContainer>
            </PickColorWrapper>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("light_dark_mode")}</InputLabel>
              <Controller
                name="light_mode"
                control={control}
                defaultValue={subjectDetails ? subjectDetails?.light_mode?.toString() : ""}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("light_mode")}</MenuItem>
                    <MenuItem value="false">{tCommon("dark_mode")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {!isRegularInstructor && (
              <>
                <PopupSectionTitle>{tCommon("study_stage_college")}</PopupSectionTitle>
                <CategoriesWrappers>
                  {categoriesList?.map((placements, index) => (
                    <DisplayBreadcrumb key={index}>
                      <BreadcrumbText>{placements.breadcrumbs.replace(/\//g, " / ")}</BreadcrumbText>
                      <DeleteCategoryIcon
                        onClick={() =>
                          categoriesList?.length > 1
                            ? removeCategory(placements.id)
                            : setCategoriesValidation(tCommon("delete_category_error_message"))
                        }
                      >
                        <CloseIcon width="12" height="12" />
                      </DeleteCategoryIcon>
                    </DisplayBreadcrumb>
                  ))}
                  {categoriesValidation && Boolean(categoriesList?.length === 1) && (
                    <ErrorType>{categoriesValidation}</ErrorType>
                  )}
                  <BreadcrumbPortalButton
                    isOpen={categoriesDropdown}
                    setIsOpen={setCategoriesDropdown}
                    categorySelectRef={categorySelectRef}
                    categoriesList={categoriesList}
                    categoriesValidation={categoriesValidation}
                  >
                    {categoriesValidation && Boolean(categoriesList?.length === 0) && (
                      <CategoryError>{categoriesValidation}</CategoryError>
                    )}
                    <BreadcrumbsMenuPortal
                      categorySelectRef={categorySelectRef}
                      categoriesDropdown={categoriesDropdown}
                      setCategoriesDropdown={setCategoriesDropdown}
                      categoriesList={categoriesList}
                      setCategoriesList={setCategoriesList}
                      selectedCountry={selectedCountry}
                    />
                  </BreadcrumbPortalButton>
                </CategoriesWrappers>
              </>
            )}
            <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="status"
                control={control}
                defaultValue={subjectDetails ? subjectDetails?.status?.toString() : ""}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("active")}</MenuItem>
                    <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSubjectPopup;
