import {
  CancleButton,
  HeaderTitle,
  PopupBody,
  PopupFooter,
  PopupHeader,
  SaveButton,
  StyledRodal,
} from "./PopupsLayout.styled";
import {
  ActionButtonsWrapper,
  DeleteSubject,
  DeleteTitle,
  EditPriceMessage,
  ErrorType,
  Headerwapper,
} from "@Components/PopupsLayout/PopupsLayout.styled";
import CircularProgress from "@mui/material/CircularProgress";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";
import { DeleteIcon } from "images/icons/DeleteIcon";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  title: string;
  buttonTitle: string;
  buttonState: boolean;
  children?: React.PropsWithChildren<any>;
  noteMessage?: string;
  showDeleteButton?: boolean;
  deleteButtonStatus?: boolean;
  deleteTitle?: string;
  openDelete?: Dispatch<SetStateAction<boolean>>;
  closePopup: Dispatch<SetStateAction<boolean>>;
  errors?: string;
  isBtnLoading?: boolean;
}

const PopupsLayout = ({
  isOpen,
  title,
  buttonTitle,
  buttonState,
  children,
  noteMessage,
  showDeleteButton,
  deleteButtonStatus,
  deleteTitle,
  openDelete,
  closePopup,
  errors,
  isBtnLoading,
}: Props) => {
  const { userData } = userInfo();
  const { t: tCommon } = useTranslation("common");

  return (
    <StyledRodal visible={isOpen} onClose={() => closePopup(false)}>
      <PopupHeader>
        <Headerwapper>
          <HeaderTitle>{title}</HeaderTitle>
        </Headerwapper>
        {errors && <ErrorType>{errors}</ErrorType>}
      </PopupHeader>
      <PopupBody hasError={errors !== ""}>
        {children}
        {!userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR) && noteMessage && (
          <EditPriceMessage>{noteMessage}</EditPriceMessage>
        )}
        {showDeleteButton && (
          <ActionButtonsWrapper>
            <DeleteSubject
              variant="fullWidth"
              disabled={deleteButtonStatus}
              onClick={(e) => {
                e.preventDefault();
                closePopup(false);
                openDelete(true);
              }}
            >
              <DeleteIcon isDisabled={deleteButtonStatus} />
              <DeleteTitle isDisabled={deleteButtonStatus}>{deleteTitle}</DeleteTitle>
            </DeleteSubject>
          </ActionButtonsWrapper>
        )}
      </PopupBody>
      <PopupFooter>
        <CancleButton variant="lightseagule" type="button" onClick={() => closePopup(false)}>
          {tCommon("cancel")}
        </CancleButton>
        <SaveButton variant="default" disabled={buttonState || isBtnLoading} type="submit">
          {isBtnLoading ? <CircularProgress color="inherit" size={24} /> : buttonTitle}
        </SaveButton>
      </PopupFooter>
    </StyledRodal>
  );
};

export default PopupsLayout;
