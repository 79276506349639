import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

export default function SubjectHeaderSkeleton() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height="120px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ borderBottom: "1px solid #EFEFEF", transform: `${language === "ar" ? "scale(-1,1)" : ""}` }}
    >
      <rect x="0" y="0" rx="8" ry="8" width="128" height="96px" />
      <rect x="140" y="10" rx="8" ry="8" width="20%" height="12px" />
      <rect x="140" y="40" rx="8" ry="8" width="15%" height="12px" />
      <rect x="140" y="70" rx="8" ry="8" width="12%" height="12px" />
      <rect x="78%" y="0" rx="8" ry="8" width="200" height="56px" />
    </ContentLoader>
  );
}
