import {
  ArrowWrapper,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  RecursiveMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import { _GetCategories } from "@Services/Common";
import { RightArrowIcon } from "images/icons/RightArrowIcon";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CategoriesListType, Category } from "types/Category";

type Props = {
  depth: number;
  hoveredCategory: Category;
  series: string[];
  setCategoriesDropdown: Dispatch<SetStateAction<boolean>>;
  categoriesList: CategoriesListType[];
  setCategoriesList: Dispatch<SetStateAction<CategoriesListType[]>>;
  setDepthNumber?: Dispatch<SetStateAction<number>>;
};

export default function RecursivePortalMenu({
  hoveredCategory,
  depth,
  series,
  setCategoriesDropdown,
  categoriesList,
  setCategoriesList,
  setDepthNumber,
}: Props) {
  const {
    i18n: { language },
  } = useTranslation();

  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const getCatLink = (catSlug: string, lastCategoryId: number) =>
    setCategoriesList([...categoriesList, { id: lastCategoryId, breadcrumbs: `${series.join(" / ")} / ${catSlug}` }]);

  const { data: categoriesData, isLoading } = useQuery(
    [["onboarding-categories", language], { categoryId: hoveredCategory.id }],
    () => _GetCategories(hoveredCategory.id),
    {
      refetchOnMount: false,
      staleTime: 5 * 1000 * 60,
    }
  );

  const handleShallowRouting = (e: React.MouseEvent<Element, MouseEvent>, catId: number, catName: string) => {
    setCategoriesDropdown(false);
    getCatLink(catName, catId);
    setDepthNumber(0);
  };

  useEffect(() => {
    setRootCategoryHover(undefined);
  }, [hoveredCategory]);

  useEffect(() => {
    if (setDepthNumber) {
      setDepthNumber(depth);
    }
  }, [setDepthNumber]);

  return (
    <Fragment>
      <RecursiveMenuWrapper onPopup depth={depth} breadCrumb={Boolean(categoriesList?.length)}>
        <MainExploreDropdown isOpen={Boolean(hoveredCategory)} position="left" isRecursive>
          {isLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categoriesData?.options.map((cat: any) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    onClick={(e) => {
                      handleShallowRouting(e, cat.id, cat.name);
                    }}
                    onMouseEnter={() => setRootCategoryHover(cat)}
                  >
                    <ExploreDropdownContent>
                      {cat.emoji ? <span className="emojie">{cat.emoji}&nbsp;</span> : ""}
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </RecursiveMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && depth < 3 && (
        <RecursivePortalMenu
          depth={depth + 1}
          setDepthNumber={setDepthNumber}
          hoveredCategory={rootCategoryHover}
          series={[...series, rootCategoryHover.name]}
          setCategoriesDropdown={setCategoriesDropdown}
          categoriesList={categoriesList}
          setCategoriesList={setCategoriesList}
        />
      )}
    </Fragment>
  );
}
