import AddCoursePopup from "@Components/Popups/AddCoursePopup";
import DeleteSubjectPopup from "@Components/Popups/DeleteSubjectPopup";
import EditSubjectPopup from "@Components/Popups/EditSubjectPopup";
import EditSubjectPricePopup from "@Components/Popups/EditSubjectPricePopup";
import SortingWorningPopup from "@Components/Popups/SortingWorningPopup";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import SubjectOverviewSkeleton from "@Components/Skeletons/SubjectOverviewSkeleton";
import AnalyticsBoxs from "@Components/SubjectOverview/AnalyticsBoxs";
import OverviewHeader from "@Components/SubjectOverview/OverviewHeader";
import OverviewPrice from "@Components/SubjectOverview/OverviewPrice";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { useEffect, useState, Fragment, useContext } from "react";
import { useParams } from "react-router-dom";

const SubjectOverview = () => {
  const { subjectid } = useParams();
  const {
    isSubjectDetailsFetching,
    setSubjectId,
    addCoursePopup,
    setAddCoursePopup,
    editSubjectPrice,
    setEditSubjectPrice,
  } = useContext(TeachersDatasContext);
  const { worningSectionsSortPopup } = useTablesFilters();
  const [editSubjectPopup, setEditSubjectPopup] = useState<boolean>(false);
  const [deleteSubjectPopup, setDeleteSubjectPopup] = useState<boolean>(false);

  useEffect(() => {
    if (subjectid) {
      setSubjectId(Number(subjectid));
    }
  }, [subjectid]);

  return (
    <PagesContainer>
      <OverviewHeader openPopup={setEditSubjectPopup} />
      {!isSubjectDetailsFetching ? (
        <Fragment>
          <AnalyticsBoxs />
          <OverviewPrice openEditSubjectPrice={setEditSubjectPrice} />
        </Fragment>
      ) : (
        <SubjectOverviewSkeleton />
      )}
      <EditSubjectPopup
        isOpen={editSubjectPopup}
        closePopup={setEditSubjectPopup}
        openDeletePopup={setDeleteSubjectPopup}
      />
      <EditSubjectPricePopup isOpen={editSubjectPrice} closePopup={setEditSubjectPrice} />
      <AddCoursePopup isOpen={addCoursePopup} closePopup={setAddCoursePopup} />
      <DeleteSubjectPopup isOpen={deleteSubjectPopup} closePopup={setDeleteSubjectPopup} subjectid={subjectid} />
      <SortingWorningPopup isOpen={worningSectionsSortPopup} />
    </PagesContainer>
  );
};

export default SubjectOverview;
