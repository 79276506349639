import {
  AnalyticsContainer,
  AnalyticsStyledBox,
  AnalyticsTitle,
  BoxsContainer,
  DataNumbers,
  IconWrapper,
  NumbersContainer,
  NumbersTitle,
} from "./AnalyticsBoxs.styled";
import { TeachersDatasContext } from "context/Teachers.context";
import { CameraIcon } from "images/icons/CameraIcon";
import { ClockIcon } from "images/icons/ClockIcon";
import { NoteIcon } from "images/icons/NoteIcon";
import { UserSegauleIcon } from "images/icons/UserSegauleIcon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { secondsToHms } from "utils/Time";

const AnalyticsBoxs = () => {
  const { subjectDetails } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  return (
    <AnalyticsContainer>
      <AnalyticsTitle>{tCommon("analytics")}</AnalyticsTitle>
      <BoxsContainer>
        {/* Hide it for now because its not send from backend */}
        {/* <AnalyticsStyledBox>
          <IconWrapper color={"rgba(254, 199, 108, 0.1)"}>
            <GlassIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("views")}</NumbersTitle>
            <DataNumbers>{subjectDetails?.views_count || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox> */}
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(69, 170, 242,0.1)"}>
            <UserSegauleIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("subscribers")}</NumbersTitle>
            <DataNumbers>{subjectDetails?.subscribers_count || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>

        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(19, 175, 114,0.1)"}>
            <CameraIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("videos")}</NumbersTitle>
            <DataNumbers>{subjectDetails?.videos_count || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
      </BoxsContainer>
      <BoxsContainer>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(242, 81, 81,0.1)"}>
            <NoteIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("notes")}</NumbersTitle>
            <DataNumbers>{subjectDetails?.notes_count || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(254, 199, 108,0.1)"}>
            <ClockIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("duration")}</NumbersTitle>
            <DataNumbers>{secondsToHms(subjectDetails?.videos_duration) || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
        {/* Hide it for now because its not send from backend */}
        {/* <AnalyticsStyledBox>
          <IconWrapper color={"rgba(174, 140, 204,0.1)"}>
            <SheetIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("subscriptions")}</NumbersTitle>
            <DataNumbers>{subjectDetails?.subscriptions_count || 0}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox> */}
      </BoxsContainer>
    </AnalyticsContainer>
  );
};

export default AnalyticsBoxs;
