import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _UpdateCourse } from "@Services/Courses";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormHelperText, InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CourseData, Course } from "types/Courses";

interface Props {
  data: CourseData;
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
  refetchData: () => void;
}

const EditCoursePopup = ({ data, isOpen, closePopup, openDeletePopup, refetchData }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const [disablebutton, setDisablebutton] = useState(true);
  const { userData } = userInfo();
  const {
    courseId,
    refetchSubjectCourses,
    teacherId,
    refetchCourseDetails,
    refetchSubjectDetails,
    setEditSubjectPrice,
  } = useContext(TeachersDatasContext);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<Course>();

  const isFreeCourse = Boolean(watch("is_free") === "true");
  const showEditPrice =
    Boolean(watch("price") !== data?.price) ||
    Boolean(watch("is_active")?.toString() !== data?.is_active?.toString()) ||
    Boolean(watch("is_free")?.toString() !== data?.is_free?.toString());
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);
  const isAdmin = !userData?.roles.includes(ADMIN);

  useEffect(() => {
    reset({
      link: data?.link,
      vimeo_folder_id: data?.vimeo_folder_id,
      vimeo_account: data?.vimeo_folder_id ? data?.vimeo_account : "",
      is_free: data?.is_free.toString(),
      price: data?.price,
      discount: data?.discount,
      is_active: data?.is_active,
      name: data?.name ? data?.name : "",
      display_name: {
        en: data?.display_name?.en ? data?.display_name?.en : "",
        tr: data?.display_name?.tr ? data?.display_name?.tr : "",
        ar: data?.display_name?.ar ? data?.display_name?.ar : "",
      },
      description: {
        en: data?.description?.en ? data?.description?.en : "",
        tr: data?.description?.tr ? data?.description?.tr : "",
        ar: data?.description?.ar ? data?.description?.ar : "",
      },
    });
    setSubmitValidation("");
    setDisablebutton(true);
  }, [data, isOpen]);

  useEffect(() => {
    if (isFreeCourse) {
      setValue("discount", 0);
      setValue("price", 0);
    } else {
      setValue("discount", data?.discount);
      setValue("price", data?.price);
    }
  }, [watch("is_free")]);

  const submitForm = (data: Course) => {
    const { name, description, display_name } = data;
    setIsLoading(true);
    _UpdateCourse(
      courseId,
      isRegularInstructor
        ? { name, description, display_name }
        : {
            ...data,
            is_active: data?.is_active?.toString() === "false" ? false : true,
            is_free: data?.is_free?.toString() === "false" ? false : true,
          },
      teacherId
    )
      .then(() => {
        closePopup(false);
        if (showEditPrice && userData?.roles.includes(ADMIN)) {
          setEditSubjectPrice(true);
        } else {
          setEditSubjectPrice(false);
        }
        refetchData();
        refetchSubjectCourses();
        refetchCourseDetails();
        refetchSubjectDetails();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_course")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton)}
          closePopup={closePopup}
          noteMessage={tCommon("edit_item_message")}
          showDeleteButton
          deleteButtonStatus={isRegularInstructor}
          deleteTitle={tCommon("delete_course")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <Input
              id="filled-basic"
              variant="filled"
              disabled={isAdmin}
              label={tCommon("link")}
              {...register("link", {
                pattern: {
                  // 1- No spaces 2- No Arabic letters 3- No links
                  value: /^(?!.*(?:http:\/\/|https:\/\/))^(?!.*\.[^\s]+)(?=[^\u0600-\u06FF\s]*$).*$/,
                  message: tCommon("course_link_validation"),
                },
              })}
              error={Boolean(errors.link)}
              helperText={errors.link && errors.link?.message}
            />
            <Input
              id="filled-basic"
              variant="filled"
              disabled={isRegularInstructor}
              label={tCommon("internal_name")}
              {...register("name", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name?.message}
            />
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                disabled={isRegularInstructor}
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
              />
              <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue={data ? data?.vimeo_account : ""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account")}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("pricing")}</InputLabel>
              <Controller
                name="is_free"
                control={control}
                defaultValue={data ? data?.is_free?.toString() : ""}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="false">{tCommon("paid")}</MenuItem>
                    <MenuItem value="true">{tCommon("free")}</MenuItem>
                  </Select>
                )}
              />
              {errors.is_free && <FormHelperText>{errors.is_free?.message}</FormHelperText>}
            </FormControl>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("course_cost")}
                disabled={isFreeCourse || isRegularInstructor}
                InputLabelProps={{
                  shrink: isFreeCourse || undefined,
                }}
                {...register("price", {
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                error={Boolean(errors.price)}
                helperText={errors.price && !isFreeCourse && errors.price?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                className="noArrows no_padding"
                type="number"
                label={tCommon("discount")}
                InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }}
                {...register("discount", {
                  min: {
                    value: 0,
                    message: tCommon("discount_less_validation"),
                  },
                  max: {
                    value: 100,
                    message: tCommon("discount_higher_validation"),
                  },
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                disabled={isFreeCourse || isRegularInstructor}
                error={Boolean(errors.discount)}
                helperText={errors.discount && watch("is_free") !== "true" && errors.discount?.message}
              />
            </InputsWrapper>
            <FormControl variant="filled" fullWidth error={Boolean(errors.is_active)} disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="is_active"
                control={control}
                defaultValue={data ? data?.is_active?.toString() : ""}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("active")}</MenuItem>
                    <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
              {errors.is_active && <FormHelperText>{errors.is_active?.message}</FormHelperText>}
            </FormControl>
            <MultibleLanguagesInputs
              data={data}
              isOpen={isOpen}
              errors={errors}
              setValue={setValue}
              register={register}
              setDisablebutton={setDisablebutton}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditCoursePopup;
