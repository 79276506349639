import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

export default function SubjectOverviewSkeleton() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="750px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ transform: `${language === "ar" ? "scale(-1,1)" : ""}` }}
    >
      <rect x="0" y="20" rx="4" ry="4" width="15%" height="12px" />
      <rect x="0" y="60" rx="8" ry="8" width="49%" height="72px" />
      <rect x="51%" y="60" rx="8" ry="8" width="49%" height="72px" />
      <rect x="0" y="150" rx="8" ry="8" width="49%" height="72px" />
      <rect x="51%" y="150" rx="8" ry="8" width="49%" height="72px" />
      <rect x="0" y="260" rx="4" ry="4" width="15%" height="12px" />
      <rect x="0" y="300" rx="8" ry="8" width="100%" height="76px" />
    </ContentLoader>
  );
}
