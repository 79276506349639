import styled from "styled-components";

export const OverviewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
  padding-bottom: 20px;
`;

export const SubjectCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const SubjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .muiltr-34he1w-MuiRating-decimal {
    margin: 0px 1.5px;
  }
`;

export const RatingNumber = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const SubjectImg = styled.img`
  width: 128px;
  height: 96px;
  border-radius: 4.89172px;
  background-color: ${({ theme }) => theme.colors.gray5_old};
`;

export const SubjectImgPlaceHolder = styled.div`
  width: 128px;
  height: 96px;
  border-radius: 4.89172px;
  background-color: ${({ theme }) => theme.colors.gray5_old};
`;

export const SubjectTitle = styled.h4`
  margin-bottom: 4px;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const SubjectLastUpdated = styled.span`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const EditSubjectBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 196px;
  height: 52px;
  background: linear-gradient(180deg, #45aaf2 0%, #32a1f0 100%);
  border-radius: 8px;
  cursor: pointer;
`;

export const EditButtonText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
`;
