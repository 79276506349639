import { AxiosError } from "axios";

export function calculateDiscount(price: number, finalPrice: number) {
  if (!finalPrice || isNaN(price)) {
    return 0;
  } else {
    const discountRate = ((price - finalPrice) / price) * 100;
    return Math.ceil(discountRate);
  }
}

export function calculateFinalPrice(price: number, discountPercentage: number) {
  if (isNaN(price) || isNaN(discountPercentage)) {
    return 0;
  } else {
    const discountAmount = (price * discountPercentage) / 100;
    const finalPrice = price - discountAmount;
    return Math.ceil(finalPrice);
  }
}

export const getErrorMessage = (
  error: AxiosError<{ message?: string; errors?: Record<string, string[]> }>,
  defaultMessage: string
): string => {
  if (!error.response || !error.response.data) return defaultMessage;

  const { errors, message } = error.response.data;

  if (errors && Object.keys(errors).length > 0) {
    const firstKey = Object.keys(errors)[0];
    return errors[firstKey][0] || defaultMessage;
  }

  return message || defaultMessage;
};

export function removeEmptyProperties(obj) {
  const newObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === undefined || value === "" || Number.isNaN(value)) {
      continue;
    }
    if (typeof value === "object") {
      const subObj = removeEmptyProperties(value);
      if (Object.keys(subObj).length !== 0) {
        newObj[key] = subObj;
      }
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
}
