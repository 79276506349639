import RecursivePortalMenu from "./RecursivePortalMenu";
import {
  ArrowWrapper,
  BreadcrumbPopupContainer,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  MainExploreMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import { _GetCategories } from "@Services/Common";
import { RightArrowIcon } from "images/icons/RightArrowIcon";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CategoriesListType, Category } from "types/Category";

type Props = {
  categorySelectRef?: React.RefObject<HTMLDivElement>;
  categoriesDropdown: boolean;
  setCategoriesDropdown: Dispatch<SetStateAction<boolean>>;
  categoriesList: CategoriesListType[];
  setCategoriesList: Dispatch<SetStateAction<CategoriesListType[]>>;
  selectedCountry: string;
};

const BreadcrumbsMenuPortal = ({
  categorySelectRef,
  categoriesDropdown,
  setCategoriesDropdown,
  categoriesList,
  setCategoriesList,
  selectedCountry,
}: Props) => {
  const {
    i18n: { language },
  } = useTranslation("common");

  const [depthNumber, setDepthNumber] = useState<number>(0);
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();

  const buttonRect = categorySelectRef?.current?.getBoundingClientRect();
  const top = buttonRect?.top + 20;
  const left = buttonRect?.left;
  const style =
    language === "ar" ? { top: `${top}px`, right: `${left - 65}px` } : { top: `${top}px`, left: `${left}px` };

  const { data: categoriesData, isLoading: categoriesLoading } = useQuery(
    ["onboarding-teacher-categories", language],
    () => _GetCategories(),
    {
      refetchOnMount: false,
    }
  );

  const categoriesHandler = (curreny: string) => {
    if (curreny?.toLowerCase() === "kwd") {
      return 1;
    } else if (curreny?.toLowerCase() === "sar") {
      return 4;
    } else if (curreny?.toLowerCase() === "jod") {
      return 130;
    } else if (curreny?.toLowerCase() === "bhd") {
      return 129;
    } else if (curreny?.toLowerCase() === "try") {
      return 211;
    }
  };

  useEffect(() => {
    if (!categoriesDropdown) {
      setDepthNumber(0);
    }
  }, [categoriesDropdown]);

  useEffect(() => {
    setRootCategoryHover(undefined);
    if (window) {
      if (categoriesDropdown) {
        window.addEventListener("mousemove", checkHover);
      } else {
        window.removeEventListener("mousemove", checkHover);
      }
    }
  }, [categoriesDropdown]);

  // Note: Product dont wanna to select first category mabye apply it later
  // const handleShallowRootCategory = (
  //   e: React.MouseEvent<Element, MouseEvent>,
  //   lastCategoryId: number,
  //   catEmoji: string
  // ) => {
  //   setCategoriesDropdown(false);
  //   setCategoriesList([...categoriesList, { id: lastCategoryId, breadcrumbs: catEmoji }]);
  //   setDepthNumber(0);
  // };

  const checkHover = useCallback((e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!dropdownRef?.current?.contains(e.target)) {
      setCategoriesDropdown(false);
    }
  }, []);

  return createPortal(
    <BreadcrumbPopupContainer style={style} depthNumber={depthNumber}>
      <MainExploreMenuWrapper isOpen={categoriesDropdown}>
        <MainExploreDropdown isOpen={categoriesDropdown} position="left">
          {categoriesLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categoriesData?.options.map((cat) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    // Note: Product dont wanna to select first category mabye apply it later
                    // onClick={(e) => {
                    //   if (currentCurrency) {
                    //     if (categoriesHandler(currentCurrency) === cat.id) {
                    //       handleShallowRootCategory(e, cat.id, cat.emoji);
                    //     }
                    //   } else {
                    //     handleShallowRootCategory(e, cat.id, cat.emoji);
                    //   }
                    // }}
                    onMouseEnter={() => {
                      if (selectedCountry) {
                        if (categoriesHandler(selectedCountry) === cat.id) {
                          setRootCategoryHover(cat);
                        }
                      } else {
                        setRootCategoryHover(cat);
                      }
                    }}
                  >
                    <ExploreDropdownContent
                      isFirstCat
                      isDisabled={categoriesHandler(selectedCountry) === cat.id}
                      selectedCountry={selectedCountry}
                    >
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </MainExploreMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && (
        <RecursivePortalMenu
          depth={0}
          setDepthNumber={setDepthNumber}
          hoveredCategory={rootCategoryHover}
          series={[rootCategoryHover.emoji]}
          setCategoriesDropdown={setCategoriesDropdown}
          categoriesList={categoriesList}
          setCategoriesList={setCategoriesList}
        />
      )}
    </BreadcrumbPopupContainer>,
    document.body
  );
};

export default BreadcrumbsMenuPortal;
