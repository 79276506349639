import { DiscountTab, EquivalentDiscount, SubjectDiscountTabWrapper } from "./SubjectDiscount.styled";
import { Input, InputsWrapper } from "@Components/common/Forms";
import { NO_DISCOUNT_TAB, PERCENTAGE_TAB, FINAL_PRICE_TAB } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext } from "react";
import { UseFormRegister, FieldErrors, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditSubjectPrice } from "types/Subjects";
import { calculateDiscount } from "utils/Forms";

type Props = {
  register: UseFormRegister<EditSubjectPrice>;
  errors: FieldErrors<EditSubjectPrice>;
  watch: UseFormWatch<EditSubjectPrice>;
  selectedOfferTab: string;
  setSelectedOfferTab: Dispatch<SetStateAction<string>>;
};

function SubjectDiscount({ register, errors, watch, selectedOfferTab, setSelectedOfferTab }: Props) {
  const { subjectDetails } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const shouldShowEquivalentDiscount =
    selectedOfferTab !== NO_DISCOUNT_TAB &&
    !errors.discount_value &&
    !errors.final_price &&
    watch("final_price") !== subjectDetails?.price;

  return (
    <>
      <SubjectDiscountTabWrapper>
        <DiscountTab
          isActive={selectedOfferTab === NO_DISCOUNT_TAB}
          onClick={() => setSelectedOfferTab(NO_DISCOUNT_TAB)}
        >
          {tCommon("no_discount")}
        </DiscountTab>
        <DiscountTab isActive={selectedOfferTab === PERCENTAGE_TAB} onClick={() => setSelectedOfferTab(PERCENTAGE_TAB)}>
          {tCommon("percentage")}
        </DiscountTab>
        <DiscountTab
          isActive={selectedOfferTab === FINAL_PRICE_TAB}
          onClick={() => setSelectedOfferTab(FINAL_PRICE_TAB)}
        >
          {tCommon("final_price")}
        </DiscountTab>
      </SubjectDiscountTabWrapper>

      <InputsWrapper>
        {selectedOfferTab !== FINAL_PRICE_TAB && (
          <Input
            id="filled-basic"
            variant="filled"
            type="number"
            className="noArrows"
            label={tCommon("discount_percentage")}
            disabled={Boolean(selectedOfferTab === NO_DISCOUNT_TAB) || subjectDetails?.price === 0}
            {...register("discount_value", {
              min: {
                value: selectedOfferTab === PERCENTAGE_TAB ? 1 : 0,
                message: tCommon("discount_validation_message"),
              },
              max: {
                value: 100,
                message: tCommon("discount_validation_message"),
              },
              required: tCommon("required_message"),
              valueAsNumber: true,
            })}
            inputProps={{
              step: "1",
              onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
              },
            }}
            error={Boolean(errors.discount_value)}
            helperText={errors.discount_value && errors.discount_value?.message}
          />
        )}
        <Input
          id="filled-basic"
          variant="filled"
          type="number"
          className="noArrows"
          label={tCommon("final_price_with_currency", { currency: subjectDetails?.currency?.toUpperCase() })}
          disabled={Boolean(selectedOfferTab !== FINAL_PRICE_TAB || subjectDetails?.price === 0)}
          {...register("final_price", {
            min: {
              value: 0,
              message: tCommon("final_price_validation_message", { amount: subjectDetails?.price - 1 }),
            },
            max: {
              value: selectedOfferTab === FINAL_PRICE_TAB ? subjectDetails?.price - 1 : subjectDetails?.price,
              message: tCommon("final_price_validation_message", { amount: subjectDetails?.price - 1 }),
            },
            required: tCommon("required_message"),
            valueAsNumber: true,
          })}
          inputProps={{
            step: "1",
            onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
            },
          }}
          error={Boolean(errors.final_price && selectedOfferTab === FINAL_PRICE_TAB)}
          helperText={errors.final_price && selectedOfferTab === FINAL_PRICE_TAB && errors.final_price?.message}
        />
      </InputsWrapper>
      {shouldShowEquivalentDiscount && (
        <EquivalentDiscount>
          {selectedOfferTab === FINAL_PRICE_TAB
            ? tCommon("equivalent_discount_with_percentage", {
                amount: isNaN(watch("final_price")) ? 0 : subjectDetails?.price - watch("final_price"),
                currency: subjectDetails?.currency?.toUpperCase(),
                percentage: calculateDiscount(subjectDetails?.price, watch("final_price")),
              })
            : tCommon("equivalent_discount", {
                amount: isNaN(watch("discount_value")) ? 0 : subjectDetails?.price - watch("final_price"),
                currency: subjectDetails?.currency?.toUpperCase(),
              })}
        </EquivalentDiscount>
      )}
    </>
  );
}

export default SubjectDiscount;
